import React, { useEffect, useContext } from "react";
import {
    Typography,
    Divider,
    Chip,
    Box,
    Grid,
    ThemeProvider,
    Button,
    LinearProgress,
    Icon,
} from "@cumplo/design-system";
import { useStyles } from "./styles";
import { Link, navigate } from "gatsby";
import Stack from "@mui/material/Stack";
import Score from "./Score";
import StatBox from "./StatBox";
import FallbackEnterprise from "src/assets/images/fallbackEnterprise.png";
import { SimulatorContext } from "../../Simulator/SimulatorContext";
import {
    coerceCurrencyProperty,
    coerceNumberWithDecimalsProperty,
} from "../../../utils/coercion";
import { plazoDescripcion } from "../../../utils/plazo";
import { isCountryChile } from "../../../utils/country";
import { getUrlPrivateSite } from "../../../utils/privateSite";

const REGION = process.env.GATSBY_REACT_APP_REGION;
const environmentLocale =
    typeof window !== "undefined" ? window.environmentLocale : ""; // FIX FOR GASBY

const HistorialCumplimiento = ({ items = [] }) => {
    const activas = items.find((h) => h.tipo === "activas");
    const completadas = items.find((h) => h.tipo === "completadas");
    const plazoNormal = items.find((h) => h.tipo === "plazo_normal");

    return (
        <Grid container ml="12px" mt="12px" flexDirection="column">
            {activas && (
                <Typography variant="caption" mb="12px" display="flex">
                    {`\u2022 `}
                    <Typography
                        variant="caption"
                        fontWeight={600}
                        ml="4px"
                        mr="4px"
                    >
                        {activas.cantidad}
                    </Typography>
                    <span> solicitudes activas.</span>
                </Typography>
            )}

            {completadas && (
                <Typography variant="caption" mb="12px" display="flex">
                    {`\u2022 `}
                    <Typography
                        variant="caption"
                        fontWeight={600}
                        ml="4px"
                        mr="4px"
                    >
                        {completadas.cantidad}
                    </Typography>
                    <span> solicitudes pagadas.</span>
                </Typography>
            )}

            {isCountryChile() && plazoNormal && (
                <Typography variant="caption" mb="12px" display="flex">
                    {`\u2022 `}
                    <Typography
                        variant="caption"
                        fontWeight={600}
                        ml="4px"
                        mr="4px"
                    >
                        {plazoNormal.cantidad}
                    </Typography>
                    <span> de los montos pagados en </span>
                    <Link ml="4px" to="/ayuda/inversiones">
                        plazo normal.
                    </Link>
                </Typography>
            )}
        </Grid>
    );
};

const OpportunityCard = ({
    cardData,
    activateProgressBar,
    activateSimulation,
}) => {
    const { setSelectedInvestmentData } = useContext(SimulatorContext);
    const classes = useStyles();
    const opportunity =
        typeof window !== "undefined"
            ? new URL(window.location.href).searchParams.get("opportunityId")
            : null;

    useEffect(() => {
        if (opportunity && opportunity == cardData.operacion.id)
            document.querySelector(`#simular-btn-${opportunity}`).click();
    }, []);

    const handleNavigation = () => {
        setSelectedInvestmentData(cardData);
        const url = getUrlPrivateSite();

        navigate(`${url}/simulador/${cardData.operacion.id}`);
    };

    return (
        <ThemeProvider>
            <div className={classes.card}>
                {/* Header */}
                <Grid container>
                    <Grid
                        xs={4}
                        md={4}
                        item
                        borderBottom="1px solid #CFD6DD"
                        height="48px"
                        display="flex"
                        justifyContent="center"
                    >
                        <img
                            src={cardData?.empresa.logo || FallbackEnterprise}
                            alt={`logo de ${cardData.nombre_fantasia}`}
                            className={classes.bannerImg}
                        />
                    </Grid>

                    <Grid xs={8} md={8} item className={classes.scoreContainer}>
                        <Score
                            text={[
                                `${
                                    cardData?.operacion?.score
                                        ? cardData?.operacion?.score?.toLocaleString(
                                              REGION,
                                              {
                                                  maximumFractionDigits: 2,
                                                  minimumFractionDigits: 2,
                                              }
                                          )
                                        : [0].toLocaleString(REGION, {
                                              maximumFractionDigits: 2,
                                              minimumFractionDigits: 2,
                                          })
                                }`,
                                "Calificación",
                            ]}
                        />
                    </Grid>
                </Grid>
                {/* Amount Progress */}
                <Grid>
                    <Grid item className={classes.paddingLeftTop}>
                        <Typography
                            variant="body2"
                            color="#6a6a6a"
                            textAlign="left"
                            letterSpacing={0}
                        >
                            ID {cardData.operacion.id}
                        </Typography>
                    </Grid>
                    <Grid>
                        <Grid item>
                            <Typography
                                variant="subtitle2"
                                gutterBottom
                                className={classes.businessName}
                            >
                                {cardData.empresa?.nombre_fantasia
                                    ? cardData.empresa?.nombre_fantasia
                                          ?.length > 50
                                        ? `${cardData.empresa?.nombre_fantasia?.slice(
                                              0,
                                              50
                                          )}...`
                                        : cardData.empresa?.nombre_fantasia
                                    : "CRÉDITO DIRECTO"}
                            </Typography>

                            <Divider className={classes.marginVertical} />

                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.amountTitle}
                            >
                                Monto a financiar
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                gutterBottom
                                className={classes.amount}
                            >
                                {coerceCurrencyProperty(
                                    cardData.operacion.monto_financiar,
                                    cardData.operacion.moneda
                                )}
                            </Typography>
                            {activateProgressBar && (
                                <Grid item>
                                    <Box
                                        marginTop={1}
                                        marginLeft={2}
                                        marginRight={2}
                                    >
                                        <LinearProgress
                                            value={
                                                cardData.operacion
                                                    .porcentaje_inversion
                                            }
                                            variant="determinate"
                                        />
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            mt="4px"
                                        >
                                            {cardData.operacion
                                                .porcentaje_inversion ===
                                                100 && (
                                                <Box mr="6px">
                                                    <Icon
                                                        name="checkCircle"
                                                        color="brand"
                                                    />
                                                </Box>
                                            )}

                                            <Typography
                                                variant="body2"
                                                color="#555F6D"
                                            >
                                                {parseInt(
                                                    cardData.operacion.porcentaje_inversion?.toLocaleString(
                                                        REGION,
                                                        {
                                                            maximumFractionDigits: 2,
                                                        }
                                                    )
                                                )}
                                                % financiada
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Divider className={classes.divider} />
                {/* Backing chips */}
                <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    ml="20px"
                >
                    <Typography variant="body2">
                        {isCountryChile() ? "Producto" : "Respaldo"}
                    </Typography>

                    <Chip
                        size="small"
                        className={classes.chip}
                        label={cardData?.operacion?.producto?.nombre || ""}
                    />
                </Stack>
                {/* Stats */}
                <Box
                    clone
                    padding={1}
                    height={60}
                    marginTop={1}
                    className={classes.operationInfo}
                >
                    <Grid
                        container
                        justifyContent="center"
                        alignContent="center"
                    >
                        <Grid item xs={4} md={4} textAlign="center">
                            <StatBox
                                text={[
                                    "Tasa anual",
                                    `${coerceNumberWithDecimalsProperty(
                                        cardData?.operacion?.tasa_anual,
                                        environmentLocale
                                    )}%`,
                                ]}
                                textTooltip="Indicador del retorno que puedes obtener al invertir dinero."
                            />
                        </Grid>
                        {isCountryChile() && (
                            <Grid item xs={4} md={4} textAlign="center">
                                <Box
                                    clone
                                    borderLeft={1}
                                    borderColor="#CFD6DD"
                                    padding="0 1rem"
                                    textAlign="center"
                                >
                                    <StatBox
                                        text={[
                                            "TIR",
                                            `${coerceNumberWithDecimalsProperty(
                                                cardData?.operacion?.tir,
                                                environmentLocale
                                            )}%`,
                                        ]}
                                        textTooltip="Tasa interna de retorno de inversión. Considera el interés de acuerdo al capital invertido, los flujos y el tiempo en que los montos son pagados. A mayor TIR, mejor retornos tendrá la inversión."
                                    />
                                </Box>
                            </Grid>
                        )}

                        <Grid item xs={4} md={4}>
                            <Box
                                clone
                                borderLeft={1}
                                borderColor="#CFD6DD"
                                padding="0 1rem"
                                textAlign="center"
                            >
                                <StatBox
                                    text={[
                                        "Plazo",
                                        plazoDescripcion(
                                            cardData?.operacion?.plazo
                                        ),
                                    ]}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <Divider className={classes.divider} />
                {/* Record */}
                <Box m={1}>
                    <Grid
                        container
                        justifyContent="start"
                        alignItems="center"
                        ml="12px"
                    >
                        <Typography
                            variant="body2"
                            textAlign="left"
                            fontWeight={500}
                        >
                            {isCountryChile() === "es-CL"
                                ? "Historial en Cumplo"
                                : "Historial de cumplimiento"}
                        </Typography>

                        {isCountryChile() && (
                            <Typography
                                variant="caption"
                                textAlign="left"
                                mt="12px"
                            >
                                Comportamiento del pagador con el solicitante.
                            </Typography>
                        )}
                    </Grid>

                    {/* HISTORIAL */}
                    <HistorialCumplimiento
                        items={cardData.empresa.historialCumplimiento}
                    />
                </Box>

                {activateSimulation && (
                    <>
                        <Divider />
                        {/* Invest button */}
                        <Box
                            display="flex"
                            justifyContent="center"
                            padding="8px"
                        >
                            <Button
                                id={`simular-btn-${cardData.operacion.id}`}
                                variant="contained"
                                disabled={
                                    cardData.operacion.porcentaje_inversion ===
                                    100
                                }
                                onClick={handleNavigation}
                                style={{ width: "100%" }}
                            >
                                {cardData.operacion.porcentaje_inversion === 100
                                    ? "Inversión completada"
                                    : "Simular inversión"}
                            </Button>
                        </Box>
                    </>
                )}
            </div>
        </ThemeProvider>
    );
};

export default OpportunityCard;
