import React from "react";
import Alert from '@mui/material/Alert';
import { Button } from "src/templates";
import * as styles from "./styles.module.css";

const PromoBanner = ({alertTitle = "", bannerTitle, bannerSubtitle, bannerText, buttonText, buttonLink, showBanner = true}) => {
    return (
        <div className={`${styles.container} ${showBanner ? "" : styles.hide}`}>
            {alertTitle.length > 0 &&
                <Alert icon={false} className={styles.alert}>
                    {alertTitle}
                </Alert>
            }
            <div className={styles.promotionBanner}>
                <div>
                    <h1 className={styles.bannertitle}>{bannerTitle}</h1>
                    <h3 className={styles.bannerSubtitle}>{bannerSubtitle}</h3>
                    <h3 className={styles.bannerText}>{bannerText}</h3>
                    <a href={buttonLink} className={styles.link}>
                        <Button className={styles.button} variant="outlined">{buttonText}</Button>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default PromoBanner;
