import React, { useState, useEffect } from "react";
import { HeroInvestments } from "src/templates";
import { getContent } from "src/queries";
import PromoBanner from "src/components/PromoBanner";
import Oportunidades from "src/components/Oportunidades";
import { InfoMessage } from "../templates";
import Filter from "../components/Oportunidades/Filter";
import { Container, Box, Grid, useMediaQuery } from "@mui/material";

import useGetOpportunities from "../hooks/useGetOpportunities";
import { filters } from "../utils/opportunitiesFilters";

export default function Inversiones() {
    const xs = useMediaQuery("(max-width:489px)");
    const [filter, setFilter] = useState("createdAt:desc");

    const filterOpportunities = (event) => {
        setFilter(event.target.value);
    };

    const heroInvestmentsBannerResults = getContent(
        "hero-investments-banner",
        process.env.GATSBY_REACT_APP_REGION
    );
    const { isLoading, opportunities, getOpportunities, allCompleted } =
        useGetOpportunities();

    useEffect(() => {
        getOpportunities(filter);
    }, [filter]);

    return (
        <>
            <PromoBanner
                bannerTitle="Tokenízate con Cumplo"
                bannerSubtitle="Rentable, líquido y simple"
                buttonText="descubre ahora"
                buttonLink="https://productos.cumplo.cl/cumplo-token"
                showBanner={process.env.GATSBY_REACT_APP_REGION != "es-CL" ? false : true}
            />
            <Box mt={process.env.GATSBY_REACT_APP_REGION != "es-CL" ? "99px" : "0px"}>
                {/* Banner no hay oportunidades para financiar */}
                {!isLoading &&
                    allCompleted &&
                    heroInvestmentsBannerResults?.disclaimer && (
                        <InfoMessage
                            body={heroInvestmentsBannerResults?.disclaimer}
                        />
                    )}

                <HeroInvestments
                    title={heroInvestmentsBannerResults?.title}
                    body={heroInvestmentsBannerResults?.body?.internal?.content}
                    backgroundDesktop={
                        heroInvestmentsBannerResults?.backgroundDesktop?.file
                            ?.url
                    }
                    backgroundMobile={
                        heroInvestmentsBannerResults?.backgroundMobile?.file
                            ?.url
                    }
                />

                <Oportunidades
                    oportunidades={opportunities}
                    loading={isLoading}
                />
            </Box>
        </>
    );
}
